export const imageFailedSvg = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="100" height="100" version="1.1" viewBox="0 0 256 256" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
 <g transform="translate(1.4066 1.4066) scale(2.81)">
\t<path d="m74.453 48.627c-5.538 0-11.075-2.107-15.291-6.324-6.11-6.11-7.768-14.99-5.024-22.629h-48.08c-3.346 1e-3 -6.058 2.713-6.058 6.059v16.322l23.834 20.315c2.278 1.942 5.573 2.119 8.047 0.434l14.382-9.801c2.33-1.588 5.408-1.531 7.677 0.141l27.15 20.001v-25.574c-2.156 0.692-4.394 1.056-6.637 1.056z" fill="#c1e5f4" stroke-linecap="round"/>
 <circle cx="27.942" cy="37.942" r="6.072" fill="#fff0a9"/>
 <path d="m85.446 16.02c-6.061-6.061-15.922-6.061-21.983 0s-6.061 15.923 0 21.984c3.031 3.031 7.011 4.546 10.992 4.546 3.98 0 7.962-1.515 10.992-4.545 2.936-2.937 4.553-6.841 4.553-10.993s-1.617-8.056-4.554-10.992zm-3.555 3.555c1.987 1.986 3.081 4.627 3.081 7.436 0 1.95-0.538 3.813-1.525 5.438l-14.428-14.428c4.043-2.442 9.384-1.934 12.872 1.554zm-14.873 14.874c-3.486-3.487-3.997-8.829-1.554-12.873l14.426 14.427c-4.043 2.443-9.385 1.932-12.872-1.554z" fill="#e29393" stroke-linecap="round"/>
 <path d="m0 40.043v32.425c0 3.346 2.712 6.058 6.058 6.058h68.974c3.346 0 6.058-2.712 6.058-6.058v-1.335l-27.15-20.001c-2.27-1.672-5.348-1.729-7.677-0.141l-14.383 9.801c-2.473 1.686-5.769 1.508-8.047-0.434l-23.833-20.315z" fill="#96ea9c" stroke-linecap="round"/>
</g>
</svg>
`;

